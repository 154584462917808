.view_cv {
  color: white;
}
.Main_div_cv {
  height: auto;
  margin-bottom: 5rem;
}

.cv_builder_container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.cv_container {
  padding-top: 15rem;
  width: 80%;
  display: flex;

  flex-direction: column;
  margin: auto;
}

.cv_title {
  font-size: 3rem;
  font-family: var(--bold);
  text-align: center;
  margin-bottom: 2rem;
  text-align: initial;
}

.underline_cv {
  width: 100%;
  height: 1px;
  background-color: #17154688;
}

.cv_title_text {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 4rem;
  font-family: var(--medium);
}

.cv_title_personal_text {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 3rem;
  font-family: var(--bold);
}

.cv_text_cont {
  display: flex;
  flex-direction: column;
  text-align: initial;
}

.cv_text_title2 {
  font-size: 1.5rem;
  font-family: var(--bold);
  margin-top: 2rem;
}

.cv_text_conts {
  margin-top: 2rem;
}

.cv_text_contents {
  font-size: 0.9rem;
}

.margin {
  margin-top: 4rem;
}

.input_container {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 80%;
  height: auto;
  gap: 2rem;
  justify-content: center;
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 0 3px 3px #00000069;
  padding: 12px 22px;
}

.divss_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
  margin: auto;
  width: 100%;
}

.right_div {
  width: 45%;
  display: flex;
  flex-direction: column;
  text-align: initial;
}

.left_div {
  width: 45%;
  display: flex;
  flex-direction: column;
  text-align: initial;
}

.button_cv_cont {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.input_div_title {
  font-size: 1.5rem;
  font-family: var(--bold);
  margin-bottom: 1rem;
}

.input_normal_builder {
  border: none;
  font-size: 1.2rem;
  font-family: var(--medium);
  width: 100%;
  text-align: initial;
  border-bottom: 1px solid var(--dark-grey);
  outline: none;
  padding-bottom: 10px;
}

.input_date_builder {
  border: none;
  font-size: 1.2rem;
  font-family: var(--medium);
  width: 150px;
  text-align: initial;
  border-bottom: 1px solid var(--dark-grey);
  outline: none;
  padding-bottom: 10px;
  margin-top: 1rem;
}

.profile_div {
  text-align: initial;
}

.profile_textarea {
  border: 1px solid var(--dark-grey);
  font-size: 1.2rem;
  font-family: var(--medium);
  width: 80%;
  text-align: initial;
  border-bottom: 1px solid var(--dark-grey);
  outline: none;
  resize: none;
  border-radius: 8px;
  padding: 20px;
  padding-bottom: 10px;
  height: 180px;
}

.education_container {
  text-align: initial;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 80%;
  height: auto;
  gap: 2rem;
  justify-content: center;
  background: #fff 0 0 no-repeat padding-box;
  box-shadow: 0 3px 3px #00000069;
  padding: 12px 22px;
}

.education_div {
  background-color: var(--navy);
  width: 120px;
  color: white;
  padding: 12px 12px 6px;
  text-align: center;
  border-radius: 8px;
}

.education_div2 {
  background-color: var(--navy);
  width: 160px;
  color: white;
  padding: 12px 12px 6px;
  text-align: center;
  border-radius: 8px;
  margin-top: 2rem;
}

.education_div_add {
  background-color: var(--navy);
  width: 120px;
  color: white;
  padding: 12px 12px 6px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}

.education_div_add_2 {
  background-color: var(--navy);
  width: 150px;
  color: white;
  padding: 12px 12px 6px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}

.education_div_add_2:hover {
  cursor: pointer;
  background-color: white;
  color: var(--navy);
  border: 2px solid var(--navy);
}

.education_div_add:hover {
  cursor: pointer;
  background-color: white;
  color: var(--navy);
  border: 2px solid var(--navy);
}

.education_div_remove {
  background-color: var(--navy);
  width: 40px;
  height: 40px;
  color: white;
  padding: 12px 12px 6px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
}

.button_contss {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.education_div_remove:hover {
  cursor: pointer;
  background-color: white;
  color: var(--navy);
  border: 2px solid var(--navy);
}

.education_title {
  font-size: 1.5rem;
  font-family: var(--bold);
  margin-bottom: 1rem;
}

.education_title_2 {
  font-size: 1.2rem;
  font-family: var(--bold);
}

.education_title_3 {
  font-size: 1.5rem;
  font-family: var(--bold);
}

.education_title_3_but {
  font-size: 1.5rem;
  font-family: var(--bold);
  background-color: var(--navy);
  color: white;
  cursor: pointer;
  padding: 3px 12px;
}

.degree_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.input_div_title {
  width: 100%;
}

.inputsss_cont {
  width: 55%;
}

.inputdesign {
  border-radius: 50%;
  border: 1px solid var(--navy);
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.inputdesignactive {
  border-radius: 50%;
  border: 1px solid var(--navy);
  background-color: var(--navy);

  width: 40px;
  height: 40px;
  cursor: pointer;
}

.inputdesign:hover {
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid var(--navy);
  background-color: var(--navy);
  width: 40px;
  height: 40px;
}

.error_text_cont {
  margin-top: 4rem;
}

.error_text {
  font-size: 1.5rem;
  color: red;
  font-family: var(--bold);
}

.generate_but {
  background-color: var(--navy);
  color: white !important;
  padding: 12px 22px;
  border-radius: 8px;
  font-size: 1.2rem;
  font-family: var(--bold);
  cursor: pointer;
  margin-top: 2rem;
  width: 150px;
  text-align: center;
  border: none;
  outline: none;
}
.generate_butdisabled {
  background-color: var(--dark-grey);
  color: white !important;
  padding: 12px 22px;
  border-radius: 8px;
  font-size: 1.2rem;
  font-family: var(--bold);
  cursor: pointer;
  margin-top: 2rem;
  width: 150px;
  text-align: center;
  border: none;
  outline: none;
}
.profile_description {
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: 500;
  font-style: italic;
}
.button_generation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

a {
  text-decoration: none;
  color: white;
}

.HELPP {
  color: var(--navy) !important;
  font-family: var(--medium);
  margin-top: 1rem;
  text-decoration: underline;
}
.red {
  color: red;
}
.no-arrows::-webkit-inner-spin-button,
.no-arrows::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-arrows {
  -moz-appearance: textfield;
}

/*mariam*/
.dates_loc {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.loc_div {
  width: 45%;
}

.dates_div {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: center;
}

@media screen and (max-width:1000px){
  .dates_loc{
    flex-direction: column;
  }
}

.dates_in {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.datesss_cont {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .dates_loc {
    flex-direction: column;
  }

  .loc_div {
    width: 100%;
  }

  .dates_div {
    flex-direction: column;
    align-items: center;
  }

  .dates_in {
    flex-direction: column;
    gap: 5px;
  }

  .datesss_cont {
    flex-direction: column;
  }
}

@media screen and (max-width: 576px) {
  .dates_loc {
    gap: 1rem;
  }

  .dates_div {
    gap: 1rem;
  }

  .dates_in {
    gap: 3px;
  }

  .loc_div {
    width: 100%;
  }
}

.level_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 150px;
}
.levelss_cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.title_level {
  font-size: 1.2rem;
  font-family: var(--bold);
  color: var(--navy);
}

@media screen and (max-width: 1000px) {
  .dates_loc {
    flex-direction: column;
  }

  .divss_container,
  .dates_div,
  .degree_div,
  .cv_container,
  .input_container,
  .education_container {
    width: 80%;
  }

  .cv_title,
  .cv_title_text,
  .cv_title_personal_text {
    font-size: 2rem;
    text-align: center;
  }

  .input_normal_builder,
  .input_date_builder,
  .profile_textarea {
    width: 90%;
  }

  .right_div,
  .left_div {
    width: 100%;
  }

  .profile_textarea {
    width: 90%;
  }

  .education_div,
  .education_div2,
  .education_div_add,
  .education_div_add_2 {
    width: 100%;
  }

  .button_cv_cont,
  .button_generation {
    flex-direction: column;
  }

  .generate_but,
  .generate_butdisabled {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .cv_title_text {
    font-size: 2.5rem;
  }

  .cv_title_personal_text {
    font-size: 2rem;
  }

  .cv_text_title2 {
    font-size: 1.2rem;
  }

  .education_title {
    font-size: 1.2rem;
  }

  .input_normal_builder,
  .input_date_builder {
    font-size: 1rem;
  }

  .profile_textarea {
    font-size: 90%;
  }
}

@media screen and (max-width: 576px) {
  .cv_title_personal_text {
    font-size:1.5rem;
  }
  .inputsss_cont {
    width:100%;
  }

  .left_div{
    width:100%;
  }
  .right_div{
    width:100%;
  }

  .dates_loc {
    gap: 1rem;
  }

  .datesss_cont {
    width:100%;
  }

  .dates_div {
    width:100%;
  }
  .dates_in {
    align-items:flex-start;
    width:100%;
  }
  .input_date_builder {
    width:100%;
  }

  .dates_div {
    gap: 0.2rem;
  }

  .dates_in {
    gap: 3px;
  }

  .loc_div {
    width: 100%;
  }

  .degree_div {
    width:100%;
    gap:.5rem;
  }

  .title_level{
    font-size:1rem;
  }



  .margin {
    margin-top:1rem;
  }



  .input_normal_builder {
    width:100%;
  }

  .input_small_builder_2 {
    width:20px;
    height:20px;
    border-radius: 10px ;
  }

  .profile_description,
  .input_div_title,
  .cv_text_contents {
    font-size: 0.9rem;
  }


  .underline_cv {
    height:0px;
  }


}

